import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import FormHelperText from "@material-ui/core/FormHelperText"

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(0),
    marginLeft: theme.spacing(0),
    minWidth: 180,
    width: 180,
  },
  error: {
    color: "red",
    marginLeft: 0,
    marginRight: 0,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    padding: "10px 14px",
  },
}))

export default function NativeSelects() {
  const classes = useStyles()
  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  })

  const handleChange = event => {
    const name = event.target.name
    setState({
      ...state,
      [name]: event.target.value,
    })
  }

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel htmlFor="en_submit">Industry</InputLabel>
        <Select
          id="en_submit"
          native
          value={state.age}
          onChange={handleChange}
          label="Industry"
          classes={{ input: classes.input }}
          inputProps={{
            name: "age",
            id: "en_submit",
          }}
        >
          <option aria-label="None" value="" />
          {/* <option
              value={
                "https://gnb1.hiringplatform.ca/161527-wnb-candidates-registration-automotive-and-transportation-sector-job-connector/672139-candidate-registration/en"
              }
            >
              Automotive and Transportation
            </option> */}

          <option
            value={
              "https://gnb1.hiringplatform.ca/39310-healthcare-event/128287-registration/en"
            }
          >
            Healthcare
          </option>
          {/* <option
              value={
                "https://gnb1.hiringplatform.ca/39322-it-event/128335-registration/en"
              }
            >
              IT
            </option> */}
          {/*<option*/}
          {/*    value={'https://gnb1.hiringplatform.ca/56297-military-job-connector/195989-registration/en'}>Military*/}
          {/*</option>*/}
          {/*<option*/}
          {/*    value={'https://gnb1.hiringplatform.ca/73878-contact-centres-virtual-job-connector/269398-registration/en'}>Contact Centres*/}
          {/*</option>*/}
          <option
            value={
              "https://gnb1.hiringplatform.ca/181066-future-gnb-student-connector/780803-registration/en"
            }
          >
            Futures GNB Student Connector
          </option>
          <option
            value={
              "https://gnb1.hiringplatform.ca/77348-candidates-registration-in-canada-recruitment-ukrainians/284806-registration/en"
            }
          >
            NB Ukrainian Job Connector
          </option>
          <option value="https://gnb1.hiringplatform.ca/81379-gnb-mosaik-program-enrollment-candidates/302830-mosaic-job-applicants/en">
            GNB Mosaïk
          </option>
          {/* <option value="https://gnb1.hiringplatform.ca/163361-manufacturing-and-construction-applicant-registration-march-29th-april-28th-2024/683175-candidate-registration/en">
              Manufacturing and Construction
            </option> */}
          {/*<option*/}
          {/*    value={'https://gnb1.hiringplatform.ca/65305-construction-forestry-manufacturing-and-trades-virtual-job-connector/231646-registration/en'}>Construction, Forestry, Manufacturing and Trades*/}
          {/*</option>*/}
          {/*<option*/}
          {/*    value={'https://gnb1.hiringplatform.ca/65307-hospitality-food-service-retail-and-other-industries-virtual-job-connector/231658-registration/en'}>Hospitality,*/}
          {/*    Food Services, Retail and Other Industries*/}
          {/*</option>*/}
        </Select>
        <FormHelperText
          id="error_msg"
          className={`${classes.error} tp tari tac-xli`}
        >
          Please Select Industry
        </FormHelperText>
      </FormControl>
    </div>
  )
}
