import React from "react"
import {Link} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./../sass/main.scss";
import Helmet from "react-helmet";
import gnbLogo from "../images/NB-LOGO.svg";
import IndustriesEN from "../components/IndustriesEN";
import IndustriesFR from "../components/IndustriesFR";


function navigateToTarget(){
    let el = document.getElementById("en_submit");
    if(el.value === ""){
       document.getElementById('error_msg').classList.remove('tp');
    }
    else{
        window.location.href = el.value;
    }
}
function navigateToTargetFR(){
    let el = document.getElementById("en_submit_fr");
    if(el.value === ""){
       document.getElementById('error_msg_fr').classList.remove('tp');
    }
    else{
        window.location.href = el.value;
    }
}
const IndexPage = () => (

    <Layout>
        <Helmet
            bodyAttributes={{
                class: 'index-page'
            }}
        />

        <SEO description="New Brunswick Job Connector | Connecteur d'emploi du Nouveau-Brunswick"
             title="New Brunswick Job Connector | Connecteur d'emploi du Nouveau-Brunswick"/>
        <div className={"b-gradient"}/>
        <div className={"px3-md w100"}>
            <div className={"container-xl ac jc mxa  bg-white bs br8 mt6 mt4-sm"}>
                <div className={"c jc ac px5 px3-sm"}>
                    <img className={"my5"} width="439" src={gnbLogo} alt="GNB Logo"/>
                    <div className={"r-c-xl w100 mt2 pb5"}>
                        <div className={"c w50 w100-xl afe ac-xl mb3-xl mb5-sm"}>
                            <h1 className={'c afe ac-xl pr5 pr0-xl mb2 d34d3'}>
                                <span className={"tar tac-xl landing-h1"}>Welcome to the New Brunswick</span>
                                <span className={"tar tac-xl landing-h1"}>Job Connector</span>
                            </h1>
                            <p className={"landing-p mb0 mt4 pr5 pr0-xl"}>Select Industry</p>
                            <div className={"pr5 pr0-xl"}>
                                <IndustriesEN className={"pr5 pr0-xl"}/>
                            </div>
                            <div className={"mt0 mt2-xl mb4 pr5 pr0-xl"}>
                                <button onClick={navigateToTarget}>Find Your Job</button>
                            </div>
                        </div>
                        <div className={"c w50 afs ac-xl w100-xl pl5 pl0-xl "}>
                            <h1 className={'c ac-xl mb2 d34d3'}>
                                <span className={"tac-xl landing-h1"}>Bienvenue au connecteur d'emploi</span>
                                <span className={"tac-xl landing-h1"}>du Nouveau-Brunswick</span>
                            </h1>
                            <p className={"landing-p mb0 mt4 pr5 pr0-xl"}>Sélectionner une industrie</p>
                             <div className={"pl0-xl"}>
                                <IndustriesFR className={"pr5 pr0-xl"}/>
                            </div>
                            <div className={"mt0 mt2-xl mb4"}>
                                <button onClick={navigateToTargetFR}>Trouvez votre emploi</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={"container-xl pt5 footer my3"}>
            <hr></hr>
            <div className={"r-c-lg jsb ac"}>
                <div className={"r"}>
                    <p className={"mb0 tac-sm"}>All content © Government of New Brunswick. All rights reserved.</p>
                </div>
                <div className={"r-c-sm mt4-lg"}>
                    <Link className={"px3 tac-sm my2-sm"} target={"_blank"}
                          to="https://www2.gnb.ca/content/gnb/en.html?_ga=2.172893923.1322334766.1613581688-1203811668.1613581688">
                        Feedback
                    </Link>
                    <Link className={"px3 tac-sm my2-sm"} target={"_blank"}
                          to="https://www2.gnb.ca/content/gnb/en/corporate/connect.html">
                        Social Media
                    </Link>
                    <Link className={"px3 tac-sm my2-sm"} target={"_blank"}
                          to="https://www2.gnb.ca/content/gnb/en/admin/privacy.html">
                        Privacy
                    </Link>
                    <Link className={"px3 tac-sm my2-sm"} target={"_blank"}
                          to="https://www2.gnb.ca/content/gnb/en/admin/disclaimer.html">
                        Disclaimer
                    </Link>
                </div>
            </div>
            {/*<div className={"r jc-lg"}>*/}
            {/*    <p className={"py3 "}><Link className={"c-green"} target={"_blank"} to={"https://vidcruiter.com/"}>Powered*/}
            {/*        by VidCruiter</Link></p>*/}
            {/*</div>*/}
        </div>
    </Layout>
);


export default IndexPage
