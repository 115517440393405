import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import FormHelperText from "@material-ui/core/FormHelperText"

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    width: 180,
  },
  error: {
    color: "red",
    marginLeft: 0,
    marginRight: 0,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

export default function NativeSelects() {
  const classes = useStyles()
  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  })

  const handleChange = event => {
    const name = event.target.name
    setState({
      ...state,
      [name]: event.target.value,
    })
  }

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel htmlFor="en_submit_fr">Industrie</InputLabel>
        <Select
          id="en_submit_fr"
          native
          value={state.age}
          onChange={handleChange}
          label="Industrie"
          inputProps={{
            name: "age",
            id: "en_submit_fr",
          }}
        >
          <option aria-label="None" value="" />
          {/* <option
            value={
              "https://gnb1.hiringplatform.ca/161527-wnb-candidates-registration-automotive-and-transportation-sector-job-connector/672139-candidate-registration/fr_CA"
            }
          >
            Automobile et transport
          </option> */}
          <option
            value={
              "https://gnb1.hiringplatform.ca/39310-healthcare-event/128287-registration/fr_CA"
            }
          >
            Santé
          </option>
          {/* <option
            value={
              "https://gnb1.hiringplatform.ca/39322-it-event/128335-registration/fr_CA"
            }
          >
            TI
          </option> */}
          {/*<option value={"https://gnb1.hiringplatform.ca/56297-military-job-connector/195989-registration/fr_CA"}>Militaires</option>*/}
          {/*<option value={"https://gnb1.hiringplatform.ca/73878-contact-centres-virtual-job-connector/269398-registration/fr_CA"}>Centres de Contact</option>*/}
          <option
            value={
              "https://gnb1.hiringplatform.ca/181066-future-gnb-student-connector/780803-registration/fr_CA"
            }
          >
            Connecteur étudiant Avenir GNB
          </option>
          <option
            value={
              "https://gnb1.hiringplatform.ca/77348-candidates-registration-in-canada-recruitment-ukrainians/284806-registration/fr_CA"
            }
          >
            Connecteur d’emploi GNB – Ukrainien:
          </option>
          <option value="https://gnb1.hiringplatform.ca/81379-inscription-au-programme-gnb-mosaik-candidats/302830-mosaic-job-applicants/fr_CA">
            GNB Mosaïk
          </option>
          {/* <option value="https://gnb1.hiringplatform.ca/163361-manufacturing-and-construction-applicant-registration-march-29th-april-28th-2024/683175-candidate-registration/fr_CA">
            Industrie manufacturière et construction
          </option> */}
          {/*<option value={"https://gnb1.hiringplatform.ca/65305-construction-forestry-manufacturing-and-trades-virtual-job-connector/231646-registration/fr_CA"}>Construction, foresterie, fabrication et métier</option>*/}
          {/*<option value={"https://gnb1.hiringplatform.ca/65307-hospitality-food-service-retail-and-other-industries-virtual-job-connector/231658-registration/fr_CA"}>Centres de contact, service de restauration, commerce de détail et autres secteurs</option>*/}
        </Select>
        <FormHelperText
          id="error_msg_fr"
          className={`${classes.error} tac-xli tp`}
        >
          Sélectionner
        </FormHelperText>
      </FormControl>
    </div>
  )
}
